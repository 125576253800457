import { render, staticRenderFns } from "./EditCompanyPage.vue?vue&type=template&id=427e3ccc&scoped=true&"
import script from "./EditCompanyPage.vue?vue&type=script&lang=js&"
export * from "./EditCompanyPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "427e3ccc",
  null
  
)

export default component.exports